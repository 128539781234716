.future-sessions {
  h4 {
    margin-top: 4px;
    margin-bottom: 10px;
  }
  .future-sessions-column {
    padding-top: 0px !important;
    .date-grid {
      padding-right: 20px;
    }
    .hour1-grid {
      label + .MuiInput-formControl {
        margin-top: 10px !important;
      }
    }
  }
  .future-sessions-subtitle {
    padding: 6px 0px 3px 24px;
    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    .subtitle {
      margin: 4px 0px 4px 8px;
    }
  }
  .empty-box {
    background: $colorBlanco;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    scroll-behavior: auto;
  }
  .shedules-box {
    padding-top: 20px;
    background: $colorBlanco;
    border: 1px solid #e9e9e9;
    justify-content: center;
    align-items: center;
    height: 360px;
    overflow-y: scroll !important;
    .error-grid {
      margin-bottom: 15px;
    }
  }
  .description-box {
    padding-bottom: 30px;
  }
  .name-grid {
    padding-left: 15px;
    margin-right: 10px;
    padding-top: 15px;
    justify-content: center;
    align-items: center;
  }

  .date-grid {
    padding-right: 20px;
  }
  .hour-grid {
    padding-left: 0px;
    padding-right: 105x;
  }
}

//sessions client
.meeting-summary {
  &__container {
    display: grid;
    grid-gap: 45px;
    padding: 12px 24px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    min-width: 300px;
    align-content: start;
    max-height: 600px;
    overflow-y: scroll;
    &--empty {
      align-content: stretch;
    }
  }
  .empty-box {
    justify-self: center;
    align-self: center;
  }
}
